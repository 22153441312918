import * as actionTypes from "app/store/actions/actionTypes";

export const fetchDocMaster = (isNewDocVersionCreated) => ({
  type: actionTypes.FETCH_DOCUMENT_MASTER,
  isNewDocVersionCreated
});
export const fetchDocMasterFailed = () => ({
  type: `${actionTypes.FETCH_DOCUMENT_MASTER}_FAILED`,
});
export const fetchDocMasterSuccess = (payload) => ({
  type: `${actionTypes.FETCH_DOCUMENT_MASTER}_SUCCESS`,
  payload,
});

export const documentStatusChange = (payload, category, searchview, isNewDocVersionCreated) => ({
  type: actionTypes.DOCUMENT_STATUS_CHANGE,
  payload,
  category,
  searchview,
  isNewDocVersionCreated
});
export const documentStatusChangeFailed = () => ({
  type: actionTypes.DOCUMENT_STATUS_CHANGE_FAILED,
});
export const documentStatusChangeSuccess = (payload) => ({
  type: actionTypes.DOCUMENT_STATUS_CHANGE_SUCCESS,
  payload,
});

export const docAddReviewComment = (payload) => ({
  type: actionTypes.ADD_REVIEW_COMMENT,
  payload,
});
export const docEmptyReviewComment = () => ({
  type: actionTypes.EMPTY_REVIEW_COMMENT,
});

export const deleteDocument = (payload, isDialog = false, isDeleteDialog = false, searchView = false) => ({
  type: actionTypes.FETCH_DOCUMENT_DELETE,
  payload,
  isDialog,
  isDeleteDialog,
  searchView
});
export const deleteDocumentSuccess = (payload) => ({
  type: `${actionTypes.FETCH_DOCUMENT_DELETE}_SUCCESS`,
  payload,
});
export const deleteDocumentFailed = () => ({
  type: `${actionTypes.FETCH_DOCUMENT_DELETE}_FAILED`,
});

export const fetchDocumentLock = (payload, searchview) => ({
  type: actionTypes.FETCH_DOCUMENT_LOCK,
  payload,
  searchview
});
export const fetchDocumentLockFailed = (payload) => ({
  type: `${actionTypes.FETCH_DOCUMENT_LOCK}_FAILED`,
  payload,
});
export const fetchDocumentLockSuccess = (payload) => ({
  type: `${actionTypes.FETCH_DOCUMENT_LOCK}_SUCCESS`,
  payload,
});
export const doGetDocumentVersionList = (payload) => ({
  type: actionTypes.GET_DOCUMENT_VERSION_LIST,
  payload
});
export const doGetDocumentVersionListSuccess = (payload) => ({
  type: `${actionTypes.GET_DOCUMENT_VERSION_LIST}_SUCCESS`,
  payload
});
export const doGetDocumentVersionListFailed = (payload) => ({
  type: `${actionTypes.GET_DOCUMENT_VERSION_LIST}_FAILED`,
  payload,
});

export const getSelectedDocumentDetail = (payload) => ({
  type: actionTypes.GET_SELECTED_DOCUMENT_DETAIL,
  payload
});
export const getSelectedDocumentDetailSuccess = (payload) => ({
  type: `${actionTypes.GET_SELECTED_DOCUMENT_DETAIL}_SUCCESS`,
  payload
});
export const getSelectedDocumentDetailFailed = (payload) => ({
  type: `${actionTypes.GET_SELECTED_DOCUMENT_DETAIL}_FAILED`,
  payload,
});
export const resetViewDialog = (payload) => ({
  type: `${actionTypes.RESET_VIEW_DIALOG}`,
  payload
});
export const docClearNote = (payload) => ({
  type: actionTypes.CLEAR_DOC_NOTE,
  payload,
});
export const addDocNote = (payload, artId) => ({
  type: actionTypes.ADD_DOC_NOTE,
  payload,
  artId
});
export const addDocNoteSuccess = (payload) => ({
  type: `${actionTypes.ADD_DOC_NOTE}_SUCCESS`,
  payload
});
export const addDocNoteFailed = (payload) => ({
  type: `${actionTypes.ADD_DOC_NOTE}_FAILED`,
  payload,
});
export const getDocNote = (payload, dropzoneId) => ({
  type: actionTypes.GET_DOC_NOTE,
  payload,
  dropzoneId
});
export const getDocNoteSuccess = (payload) => ({
  type: `${actionTypes.GET_DOC_NOTE}_SUCCESS`,
  payload
});
export const getDocNoteFailed = (payload) => ({
  type: `${actionTypes.GET_DOC_NOTE}_FAILED`,
  payload,
});
export const resolveDocNote = (payload, artId) => ({
  type: `${actionTypes.RESOLVE_DOC_NOTE}`,
  payload,
  artId
});
export const resolveDocNoteSuccess = (payload) => ({
  type: `${actionTypes.RESOLVE_DOC_NOTE}_SUCCESS`,
  payload,
});
export const resolveDocNoteFailed = (payload) => ({
  type: `${actionTypes.RESOLVE_DOC_NOTE}_FAILED`,
  payload,
});
export const setPreviousVersionId = (payload) => ({
  type: `${actionTypes.PRE_VERSION_ID}`,
  payload,
});
export const getSharedFileCount = (payload, isDocInfoUpdated) => ({
  type: actionTypes.GET_SHARED_FILE_COUNT,
  payload,
  isDocInfoUpdated
});
export const getSharedFileCountSuccess = (payload, isDocInfoUpdated) => ({
  type: `${actionTypes.GET_SHARED_FILE_COUNT}_SUCCESS`,
  payload,
  isDocInfoUpdated
});
export const getSharedFileCountFailed = (payload) => ({
  type: `${actionTypes.GET_SHARED_FILE_COUNT}_FAILED`,
  payload,
});
export const updateUserSharedList = (payload) => ({
  type: actionTypes.UPDATE_USER_SHARED_LIST,
  payload,
});
export const updateUserSharedListSuccess = (payload) => ({
  type: `${actionTypes.UPDATE_USER_SHARED_LIST}_SUCCESS`,
  payload,
});
export const updateUserSharedListFailed = (payload) => ({
  type: `${actionTypes.UPDATE_USER_SHARED_LIST}_FAILED`,
  payload,
});
export const getDocAndHistory = (payload, from) => ({
  type: actionTypes.GET_DOC_PROP_HISTROY,
  payload,
  from
});
export const getDocAndHistoryFailed = () => ({
  type: `${actionTypes.GET_DOC_PROP_HISTROY}_FAILED`,
});
export const getDocAndHistorySuccess = (payload, from) => ({
  type: `${actionTypes.GET_DOC_PROP_HISTROY}_SUCCESS`,
  payload,
  from
});
export const getDocSharingMetricsByVersionId = (payload) => ({
  type: actionTypes.GET_SHARING_METRICS_BY_VERSION,
  payload
});
export const getDocSharingMetricsByVersionIdFailed = () => ({
  type: `${actionTypes.GET_SHARING_METRICS_BY_VERSION}_FAILED`,
});
export const getDocSharingMetricsByVersionIdSuccess = (payload) => ({
  type: `${actionTypes.GET_SHARING_METRICS_BY_VERSION}_SUCCESS`,
  payload
});
export const getDocSharingUserList = (payload) => ({
  type: actionTypes.GET_DOC_SHARE_USER_LIST,
  payload
});
export const getDocSharingUserListFailed = () => ({
  type: `${actionTypes.GET_DOC_SHARE_USER_LIST}_FAILED`,
});
export const getDocSharingUserListSuccess = (payload) => ({
  type: `${actionTypes.GET_DOC_SHARE_USER_LIST}_SUCCESS`,
  payload
});
export const createBinderDetailsForSharing = (payload) => ({
  type: actionTypes.CREATE_NEW_BINDER_FOR_SHARING,
  payload
});
export const createBinderDetailsForSharingFailed = (payload) => ({
  type: `${actionTypes.CREATE_NEW_BINDER_FOR_SHARING}_FAILED`,
  payload
});
export const createBinderDetailsForSharingSuccess = (payload) => ({
  type: `${actionTypes.CREATE_NEW_BINDER_FOR_SHARING}_SUCCESS`,
  payload
});
export const getExistingSharedUsersList = (payload) => ({
  type: actionTypes.GET_EXISTING_SHARED_USER_LIST,
  payload
});
export const getExistingSharedUsersListFailed = () => ({
  type: `${actionTypes.GET_EXISTING_SHARED_USER_LIST}_FAILED`,
});
export const getExistingSharedUsersListSuccess = (payload) => ({
  type: `${actionTypes.GET_EXISTING_SHARED_USER_LIST}_SUCCESS`,
  payload
});
export const getBindersDetail = (payload) => ({
  type: actionTypes.GET_BINDERS_DETAIL,
  payload
});
export const getBindersDetailFailed = () => ({
  type: `${actionTypes.GET_BINDERS_DETAIL}_FAILED`,
});
export const getBindersDetailSuccess = (payload) => ({
  type: `${actionTypes.GET_BINDERS_DETAIL}_SUCCESS`,
  payload
});
export const clearBinderMetrics = (payload) => ({
  type: `${actionTypes.CLEAR_BINDER_METRICS}`,
  payload
});
export const isBindersCallRunning = (payload) => ({
  type: `${actionTypes.IS_BINDERS_CALL_RUNNING}`,
  payload
});
export const getDocumentContentDetails = (documentId, documentVersionId) => ({
  type: actionTypes.GET_DOCUMENT_CONTENT_DETAILS,
  documentId,
  documentVersionId
});
export const getDocumentContentDetailsFailed = () => ({
  type: `${actionTypes.GET_DOCUMENT_CONTENT_DETAILS}_FAILED`,
});
export const getDocumentContentDetailsSuccess = (payload) => ({
  type: `${actionTypes.GET_DOCUMENT_CONTENT_DETAILS}_SUCCESS`,
  payload,
});
export const reCallBinder = (payload) => ({
  type: actionTypes.RECALL_BINDER,
  payload
});
export const reCallBinderFailed = (payload) => ({
  type: `${actionTypes.RECALL_BINDER}_FAILED`,
  payload
});
export const reCallBinderSuccess = (payload) => ({
  type: `${actionTypes.RECALL_BINDER}_SUCCESS`,
  payload,
});
export const getBindersHistory = (payload) => ({
  type: actionTypes.GET_BINDERS_HISTORY,
  payload
});
export const getBindersHistoryFailed = (payload) => ({
  type: `${actionTypes.GET_BINDERS_HISTORY}_FAILED`,
  payload
});
export const getBindersHistorySuccess = (payload) => ({
  type: `${actionTypes.GET_BINDERS_HISTORY}_SUCCESS`,
  payload,
});
export const getAdminSharingSettings = () => ({
  type: actionTypes.GET_ADMIN_SHARING_SETTING
});
export const getAdminSharingSettingsFailed = (payload) => ({
  type: `${actionTypes.GET_ADMIN_SHARING_SETTING}_FAILED`,
  payload
});
export const getAdminSharingSettingsSuccess = (payload) => ({
  type: `${actionTypes.GET_ADMIN_SHARING_SETTING}_SUCCESS`,
  payload,
});
export const downloadSignatureDetails = (payload, isOnlySignatureAvailable) => ({
  type: actionTypes.DOWNLOAD_SIGNATURE_DETAILS,
  payload,
  isOnlySignatureAvailable
});
export const downloadSignatureDetailsFailed = (payload) => ({
  type: `${actionTypes.DOWNLOAD_SIGNATURE_DETAILS}_FAILED`,
  payload
});
export const downloadSignatureDetailsSuccess = (payload) => ({
  type: `${actionTypes.DOWNLOAD_SIGNATURE_DETAILS}_SUCCESS`,
  payload,
});
export const errorWithDateValue = (payload) => ({
  type: actionTypes.ERROR_WITH_DATE_VALUE,
  payload,
});
export const getHistoryDetails = (payload) => ({
  type: actionTypes.GET_HISTORY_DETAILS,
  payload,
});

export const getHistoryDetailsSuccess = (payload) => ({
  type: `${actionTypes.GET_HISTORY_DETAILS}_SUCCESS`,
  payload,
});

export const getHistoryDetailsFailure = () => ({
  type: `${actionTypes.GET_HISTORY_DETAILS}_FAILED`
});
export const downloadSharingDetails = (payload, validFilename) => ({
  type: actionTypes.DOWNLOAD_SHARING_DETAILS,
  payload,
  validFilename
});
export const downloadSharingDetailsFailed = (payload) => ({
  type: `${actionTypes.DOWNLOAD_SHARING_DETAILS}_FAILED`,
  payload
});
export const downloadSharingDetailsSuccess = (payload) => ({
  type: `${actionTypes.DOWNLOAD_SHARING_DETAILS}_SUCCESS`,
  payload,
});
export const downloadSignatureZipDetails = (payload, validFilename) => ({
  type: actionTypes.DOWNLOAD_SIGNATURE_ZIP_DETAILS,
  payload,
  validFilename
});
export const downloadSignatureZipDetailsFailed = (payload) => ({
  type: `${actionTypes.DOWNLOAD_SIGNATURE_ZIP_DETAILS}_FAILED`,
  payload
});
export const downloadSignatureZipDetailsSuccess = (payload) => ({
  type: `${actionTypes.DOWNLOAD_SIGNATURE_ZIP_DETAILS}_SUCCESS`,
  payload,
});
export const getDownloadSharingStatus = (payload, validFilename) => ({
  type: actionTypes.GET_DOWNLOAD_SHARING_STATUS,
  payload,
  validFilename
});
export const getDownloadSharingStatusFailed = (payload) => ({
  type: `${actionTypes.GET_DOWNLOAD_SHARING_STATUS}_FAILED`,
  payload
  });
export const getDownloadSharingStatusSuccess = (payload) => ({
  type: `${actionTypes.GET_DOWNLOAD_SHARING_STATUS}_SUCCESS`,
  payload,
});