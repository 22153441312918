import { put, takeEvery, delay, takeLatest } from "redux-saga/effects";
import * as actionTypes from "app/store/actions/actionTypes";
import * as actions from "app/store/actions/documentAction";
import * as actionsZone from "app/store/actions/zoneActions";
import * as dialogActions from "app/store/actions/dialogAction";
import * as fileUploadActions from "app/store/actions/fileuploadActions";
import { DocumentService } from "app/secure/services/documentService";
import * as actionsLoader from "app/store/actions/loaderActions";
import i18n from "i18nnext";
import { StorageHelper } from "app/core/storageHelper";
import _ from "underscore";
import { UtilsHelper } from "app/core/utilsHelper";
import * as templateActions from "app/store/actions/templateActions";
import AzureFileuploadHelper from "app/core/azureFileuploadHelper";

export function* fetchDocumentMaster(data) {
  yield put(actionsLoader.showLoader());
  try {
    const response = yield DocumentService.doGetDocumentMaster();
    const { result, isError } = response.data;
    if (!isError) {
      const { zoneDataDto } = result;
      yield put(actions.fetchDocMasterSuccess(result));
      yield put(actionsZone.zoneDataFetchSuccess(zoneDataDto.zonedata));
    }
    if (!data.isNewDocVersionCreated) {
      yield delay(3000);
      yield put(actionsLoader.hideLoader());
    }
  } catch (error) {
    yield put(actionsLoader.hideLoader());
    yield put(actions.fetchDocMasterFailed({}));
  }
}

export function* documentStatusChange(data) {
  try {
    yield put(actionsLoader.showLoader());
    let response = null;
    if (data.payload.workflowAction === "publish") {
      response = yield DocumentService.docPublishDocDetails(data.payload);
    } else if (data.payload.workflowAction === "Approve") {
      response = yield DocumentService.docApproveDoc(data.payload);
    } else if (data.payload.workflowAction === "unpublish") {
      response = yield DocumentService.docUnPublishDocDetails(data.payload);
    }
    yield put(actionsLoader.hideLoader());
    if (!response.data.isError) {
      StorageHelper.clearFilteredData();
      const { ismodified } = response.data.result;
      if (!ismodified) {
        yield put(actions.documentStatusChangeSuccess(response.data.result));
        let lastdocumentupdatedon = sessionStorage.getItem("lastdocumentupdatedon");
        let req = {
          searchKey: "",
          site: "ALL",
          countryCode: "ALL",
          pageCount: 0,
          pageSize: 20000,
          pageNumber: 1,
          artifactId: "",
          sectionid: "",
          category: "",
          milestoneGroup: "ALL",
          milestoneId: "",
          lastdocumentupdatedon: lastdocumentupdatedon
        };

        let recentSearchInfo = StorageHelper.getRecentSearchInfo();
        if (!_.isEmpty(recentSearchInfo)) {
          StorageHelper.setRecentSearchInfo(recentSearchInfo);
        } else {
          StorageHelper.setRecentSearchInfo(req);
        }

        const { oldOrigiArtifactid, currentArtiId } = data.payload;
        // const { origiartifactid } = response.data.result;
        if (!_.isEmpty(data.category)) {
          yield put(templateActions.fetchDropZoneDocuments(data.category));
          yield put(templateActions.getDropZoneSettings(data.category));
        }
        if (!_.isEmpty(oldOrigiArtifactid))
          yield put(actionsZone.getDocumentsByArtifact(oldOrigiArtifactid));
        if (data.searchview === true) {
          const recentSectionId = StorageHelper.getRecentSectionID();
          if (!_.isEmpty(recentSectionId))
            yield put(actionsZone.getDocumentsBySection(recentSectionId));
          const recentSearchInfo = StorageHelper.getRecentSearchInfo();
          if (!_.isEmpty(recentSearchInfo) && recentSearchInfo !== "") {
            yield put(actionsZone.fetchSearchResult(recentSearchInfo));
          }
        }
        yield put(actions.fetchDocMaster());
        yield put(actionsZone.fetchMetricsData());
        if (data.payload.workflowAction === "Approve") {
          yield put(dialogActions.showViewDocDialog({}));
        } else if (
          data.payload.workflowAction === "unpublish" ||
          data.payload.workflowAction === "publish"
        ) {
          yield put(dialogActions.showPublishDialog({}));
        }
        let searchView = (data.searchview === true) ? false : true;
        let isFileScan = StorageHelper.getIsAGGridApiRunning();
        if (isFileScan == "false" && !_.isEmpty(recentSearchInfo) && recentSearchInfo === "") {
          StorageHelper.setIsAGGridApiRunning(true);
          yield put(actionsZone.fetchAllAggridData(req, searchView));
        }
        yield put(fileUploadActions.createNewDocVersionFailed());
      } else {
        yield put(
          dialogActions.updateErrorAlertDialog(
            i18n.t("TMF.TOAST_DOCUPDATEFAILEDREFRESHRELOAD")
          )
        );
      }
    } else {
      const errorMessage = UtilsHelper.constructErrorMsg(response.data);
      if (errorMessage.includes("data was changed")) {
        yield put(dialogActions.updateErrorAlertDialog(
          i18n.t("TMF.TOAST_DOCUPDATEFAILEDREFRESHRELOAD")
        ))
      } else {
        yield put(dialogActions.updateErrorAlertDialog(errorMessage));
      }
      yield put(actions.documentStatusChangeFailed());
    }
  } catch (e) {
    yield put(
      dialogActions.updateErrorAlertDialog(i18n.t("TMF.TOAST_DOCUPDATEFAILED"))
    );
    yield put(actions.documentStatusChangeFailed());
  }
}

export function* deleteDocumentSaga(data) {
  const { payload, isDialog, isDeleteDialog, searchView } = data;
  try {
    const response = yield DocumentService.doDocumentDelete(payload);
    const { isError } = response.data;
    if (!isError) {
      if (isDeleteDialog) yield put(actionsLoader.hideLoader());
      const { result } = response.data;
      const docContent = result.documentcontents
        ? JSON.parse(result.documentcontents)
        : null;
      const { documentDetails } = docContent;

      const recentSearchInfo = StorageHelper.getRecentSearchInfo();
      if (!_.isEmpty(recentSearchInfo) && searchView === true && recentSearchInfo.searchKey !== "") {
        yield put(actionsZone.fetchSearchResult(recentSearchInfo));
      }

      yield put(actions.deleteDocumentSuccess(documentDetails));
      let lastdocumentupdatedon = sessionStorage.getItem("lastdocumentupdatedon");
      let req = {
        searchKey: "",
        site: "ALL",
        countryCode: "ALL",
        pageCount: 0,
        pageSize: 20000,
        pageNumber: 1,
        artifactId: "",
        sectionid: "",
        category: "",
        milestoneGroup: "ALL",
        milestoneId: "",
        lastdocumentupdatedon: lastdocumentupdatedon
      };
      let currentView = (searchView === true) ? false : true;
      if (!_.isEmpty(recentSearchInfo)) {
        StorageHelper.setRecentSearchInfo(recentSearchInfo);
      } else {
        StorageHelper.setRecentSearchInfo(req);
      }
      yield put(actions.fetchDocMaster());
      yield put(actionsZone.fetchMetricsData());
      if (isDialog) yield put(dialogActions.showViewDocDialog({}));
      if (isDeleteDialog) yield put(dialogActions.showPublishDialog({}));
      let isFileScan = StorageHelper.getIsAGGridApiRunning();
      if (isFileScan == "false" && !_.isEmpty(recentSearchInfo) && recentSearchInfo.searchKey === "") {
        StorageHelper.setIsAGGridApiRunning(true);
        yield put(actionsZone.fetchAllAggridData(req, currentView));
      }

    } else {
      if (isDeleteDialog) yield put(actionsLoader.hideLoader());
      const errorMessage = UtilsHelper.constructErrorMsg(response.data);
      if (isDialog) {
        yield put(
          dialogActions.updateErrorAlertDialog(i18n.t("TMF.TOAST_DOCUPDATEFAILEDREFRESHRELOAD"))
        );
      } else {
        yield put(
          actionsLoader.showError(i18n.t("TMF.TOAST_DOCUPDATEFAILEDREFRESHRELOAD"))
        );
      }
      yield put(actions.deleteDocumentFailed());
    }
  } catch (error) {
    if (isDeleteDialog) yield put(actionsLoader.hideLoader());
    yield put(actions.deleteDocumentFailed());
    if (isDialog) {
      yield put(
        dialogActions.updateErrorAlertDialog(i18n.t("TMF.TOAST_DOCDELETEFAILED"))
      );
    } else {
      yield put(actionsLoader.showError(i18n.t("TMF.TOAST_DOCDELETEFAILED")));
    }
  }
}
export function* fetchDocumentLockSaga(data) {
  yield put(actionsLoader.showLoader());
  try {
    const response = yield DocumentService.doDocumentLock(data.payload);
    const { result, isError } = response.data;
    if (!isError) {
      yield put(actions.fetchDocumentLockSuccess(result));
      if (!result.islocked) {
        const { origiartifactid } = result;
        if (!_.isEmpty(origiartifactid))
          yield put(actions.fetchDocMaster());
        yield put(actionsZone.getDocumentsByArtifact(origiartifactid));
      }
      const recentSearchInfo = StorageHelper.getRecentSearchInfo();
      if (data.searchview === true) {
        if (!_.isEmpty(recentSearchInfo) && recentSearchInfo !== "") {
          yield put(actionsZone.fetchSearchResult(recentSearchInfo));
        }
      }
      let lastdocumentupdatedon = sessionStorage.getItem("lastdocumentupdatedon");
      let req = {
        searchKey: "",
        site: "ALL",
        countryCode: "ALL",
        pageCount: 0,
        pageSize: 20000,
        pageNumber: 1,
        artifactId: "",
        sectionid: "",
        category: "",
        milestoneGroup: "ALL",
        milestoneId: "",
        lastdocumentupdatedon: lastdocumentupdatedon
      };
      let searchView = (data.searchview === true) ? false : true;
      let isFileScan = StorageHelper.getIsAGGridApiRunning();
      if (isFileScan == "false" && !_.isEmpty(recentSearchInfo) && recentSearchInfo === "") {
        StorageHelper.setIsAGGridApiRunning(true);
        yield put(actionsZone.fetchAllAggridData(req, searchView));
      }

      if (!_.isEmpty(recentSearchInfo)) {
        StorageHelper.setRecentSearchInfo(recentSearchInfo);
      } else {
        StorageHelper.setRecentSearchInfo(req);
      }
      yield put(actionsZone.fetchMetricsData());

      yield put(dialogActions.showViewDocDialog({}));
    } else {
      const errorMessage = UtilsHelper.constructErrorMsg(response.data);
      yield put(dialogActions.updateErrorAlertDialog(errorMessage));
    }
    yield put(actionsLoader.hideLoader());

  } catch (error) {
    yield put(actionsLoader.hideLoader());
    yield put(actions.fetchDocumentLockFailed({}));
  }
}

export function* doGetDocumentVersionListSaga(data) {
  try {
    const response = yield DocumentService.doGetDocumentVersionList(data.payload);
    const { result, isError } = response.data;

    if (!isError) {
      yield put(actions.doGetDocumentVersionListSuccess(result));
    }
  } catch (error) {
    yield put(actions.doGetDocumentVersionListFailed({}));
  }
}

export function* getSelectedDocumentDetailSaga(data) {
  try {
    yield put(actionsLoader.showLoader());
    const response = yield DocumentService.getSelectedDocumentDetail(data.payload);
    const { result, isError } = response.data;

    if (!isError) {
      yield put(actions.getSelectedDocumentDetailSuccess(result.documentDetails));
      yield put(actions.getDocNoteSuccess(result.documentnotes));
      yield put(actionsLoader.hideLoader());
    } else {
      yield put(actionsLoader.hideLoader());
    }
  } catch (error) {
    yield put(actionsLoader.hideLoader());
    yield put(actions.getSelectedDocumentDetailFailed({}));
  }
};
export function* addDocNoteSaga(data) {
  try {
    yield put(actionsLoader.showLoader());
    const response = yield DocumentService.addDocNote(data.payload);
    const { result, isError } = response.data;
    if (!isError) {
      yield put(actions.addDocNoteSuccess(result));
      yield put(actionsLoader.hideLoader());
    } else {
      yield put(actionsLoader.hideLoader());
    }
  } catch (error) {
    yield put(actionsLoader.hideLoader());
    yield put(actions.addDocNoteFailed());
  }
};
export function* getDocNoteSaga(data) {
  try {
    yield put(actionsLoader.showLoader());
    let versionId = data.payload;
    let dropzoneId = data.dropzoneId;
    const response = yield DocumentService.getDocNote(versionId, dropzoneId);
    const { result, isError } = response.data;
    if (!isError) {
      yield put(actions.getDocNoteSuccess(result));
      yield put(actionsLoader.hideLoader());
    } else {
      yield put(actionsLoader.hideLoader());
    }
  } catch (error) {
    yield put(actionsLoader.hideLoader());
    yield put(actions.getDocNoteFailed());
  }
};
export function* resolveDocNoteSaga(data) {
  try {
    yield put(actionsLoader.showLoader());
    const response = yield DocumentService.resolveDocNote(data.payload);
    const { result, isError } = response.data;
    if (!isError) {
      yield put(actions.resolveDocNoteSuccess(result));
      yield put(actions.getDocNoteSuccess(result));
      yield put(actionsLoader.hideLoader());
    } else {
      yield put(actionsLoader.hideLoader());
      yield put(
        dialogActions.updateErrorAlertDialog(
          i18n.t("TMF.TOAST_DOCUPDATEFAILEDREFRESHRELOAD")
        )
      );
    }
  } catch (error) {
    yield put(actionsLoader.hideLoader());
    yield put(actions.resolveDocNoteFailed());
    yield put(
      dialogActions.updateErrorAlertDialog(
        i18n.t("TMF.TOAST_DOCUPDATEFAILEDREFRESHRELOAD")
      )
    );
  }
};
export function* getSharedFileCount(data) {
  try {
    const response = yield DocumentService.doGetSharedFileCount(data.payload);
    const { result, isError } = response.data;

    if (!isError) {
      yield put(actions.getSharedFileCountSuccess(result, data.isDocInfoUpdated));
    }
  } catch (error) {
    yield put(actions.getSharedFileCountFailed({}));
  }
}

export function* updateUserSharedListSaga(action) {
  try {
    const response = yield DocumentService.updateUserSharedListService(action.payload);
    const { isError } = response.data;
    if (!isError) {
      yield put(actions.getSharedFileCount(action.payload.fileguid, false));
    }
  } catch (error) {
    yield put(actions.updateUserSharedListFailed({}));
  }
}
export function* getDocPropHistorySaga(action) {
  try {
    const response = yield DocumentService.getDocumentPropertiesAndHistory(action.payload);
    const { isError, result } = response.data;
    if (!isError) {
      yield put(actions.getDocAndHistorySuccess(result, action.from));
    }
  } catch (error) {
    yield put(actions.getDocAndHistoryFailed());
  }
}
export function* getDocSharingMetricsByVersionIdSaga(action) {
  try {
    const response = yield DocumentService.getDocumentSharingMetricsByVersion(action.payload);
    const { isError, result } = response.data;
    if (!isError) {
      yield put(actions.getDocSharingMetricsByVersionIdSuccess(result));
      if (result.availableBindersWithRecalledCount > 0) {
        yield put(actions.getExistingSharedUsersList(action.payload));
      }
      yield put(actionsLoader.hideLoader());
    }
  } catch (error) {
    yield put(actions.getDocSharingMetricsByVersionIdFailed());
  }
}
export function* getDocSharingUserSaga(action) {
  try {
    const response = yield DocumentService.getDocumentSharingUsers(action.payload);
    const { isError, result } = response.data;
    if (!isError) {
      yield put(actions.getDocSharingUserListSuccess(result));
    }
  } catch (error) {
    yield put(actions.getDocSharingUserListFailed());
  }
}
export function* getExistingSharedUserListSaga(action) {
  try {
    yield put(actions.isBindersCallRunning(true));
    const response = yield DocumentService.getExistingSharedUserList(action.payload);
    const { isError, result } = response.data;
    if (!isError) {
      yield put(actions.getExistingSharedUsersListSuccess(result));
      yield put(actions.isBindersCallRunning(false));
    }
  } catch (error) {
    yield put(actions.getExistingSharedUsersListFailed());
    yield put(actions.isBindersCallRunning(false));
  }
}
export function* getBindersDetailsSaga(action) {
  try {
    const response = yield DocumentService.getBindersDetails(action.payload);
    const { isError, result } = response.data;
    if (!isError) {
      yield put(actions.getBindersDetailSuccess(result));
      yield put(actions.getDocumentContentDetailsSuccess(result.documentContentDetails));
    }
  } catch (error) {
    yield put(actions.getBindersDetailFailed());
  }
}
export function* createNewBinderForSharingSaga(action) {
  try {
    yield put(actionsLoader.showLoader());
    const response = yield DocumentService.createBinderForSharing(action.payload);
    const { isError, result } = response.data;
    if (!isError) {
      yield put(actions.getDocSharingMetricsByVersionId(action.payload.documentid));
      yield put(actions.getExistingSharedUsersList(action.payload.documentid));
    } else {
      yield put(actions.createBinderDetailsForSharingFailed(response.data.responseException.exceptionMessage))
      yield put(actionsLoader.hideLoader());
    }
  } catch (error) {
    yield put(actionsLoader.hideLoader());
    yield put(actions.getBindersDetailFailed());
  }
}
export function* getDocumentContentDetailsSaga(action) {
  const { documentVersionId, documentId } = action;
  try {
    const response = yield DocumentService.getDocumentContentDetails(documentId, documentVersionId);
    const { isError, result } = response.data;
    if (!isError) {
      yield put(actions.getDocumentContentDetailsSuccess(result));
    }
  } catch (error) {
    yield put(actions.getDocumentContentDetailsFailed());
  }
}
export function* recallBinderSaga(data) {
  try {
    yield put(actionsLoader.showLoader());
    const response = yield DocumentService.reCallBinders(data.payload);
    const { isError, result } = response.data;
    if (!isError) {
      yield put(actions.reCallBinderSuccess(result));
      yield put(actions.getBindersHistory(data.payload.sharingbinderid));
      yield put(actionsLoader.hideLoader());
    } else {
      yield put(actionsLoader.hideLoader());
      yield put(actions.reCallBinderFailed(response.data.responseException.exceptionMessage));
    }
  } catch (error) {
    yield put(actionsLoader.hideLoader());
    yield put(actions.reCallBinderFailed(error));
  }
}

export function* getBindersHistorySaga(data) {
  try {
    const response = yield DocumentService.getBindersHistory(data.payload);
    const { isError, result } = response.data;
    if (!isError) {
      yield put(actions.getBindersHistorySuccess(result));
    } else {
      yield put(actions.getBindersHistoryFailed());
    }
  } catch (error) {
    yield put(actions.getBindersHistoryFailed());
  }
}

export function* getAdminsharingsSettingSaga() {
  try {
    const response = yield DocumentService.getAdminsharingSettings();
    const { isError, result } = response.data;
    if (!isError) {
      yield put(actions.getAdminSharingSettingsSuccess(result));
    } else {
      yield put(actions.getAdminSharingSettingsFailed());
    }
  } catch (error) {
    yield put(actions.getAdminSharingSettingsFailed());
  }
}

export function* downloadSignatureDetailsSaga(data) {
  try {
    const response = yield DocumentService.downloadSignatureDetailsWithSAS(data.payload);
    const { isError, result } = response.data;
    if (!isError) {
      let fileurl = atob(result.sastoken);
      let downloadInfo = {
        downloadFileName: data.payload.friendlyfilename,
        type: "pdf"
      }
      let downFileResponse = yield AzureFileuploadHelper.downloadAzureFile(
        fileurl,
        downloadInfo
      );
      yield put(actions.downloadSignatureDetailsSuccess());
      yield put(actions.downloadSignatureDetailsFailed());

    } else {
      yield put(actions.downloadSignatureDetailsFailed());
    }
  } catch (error) {
    yield put(actions.downloadSignatureDetailsFailed());
  }
};
export function* downloadSharingDetailsSaga(action) {
  try {
    yield put(fileUploadActions.showDownloadLoader(true));
    let response = yield DocumentService.downloadSharingDetails(action.payload);
    if(!response.data.isError) {
      if(response.data.result.iszipfilecreationtriggerted) {
        let reqPayload = {
          sharingActionId: response.data.result.sharingactionid,
          zipFileName: action.validFilename
        }
        yield put(actions.getDownloadSharingStatus(reqPayload, action.validFilename));
      } else {
        yield put(fileUploadActions.showDownloadLoader(false));
      }
    } else {
      yield put(fileUploadActions.showDownloadLoader(false));
    }
    console.log(response, "response")
  } catch (error) {
    
  }
}
export function* downloadSignatureZipDetailsSaga(action) {
  try {
    yield put(fileUploadActions.showDownloadLoader(true));
    let response = yield DocumentService.downloadSignatureZipDetails(action.payload);
    if(!response.data.isError) {
      if(response.data.result.iszipfilecreationtriggerted) {
        let reqPayload = {
          sharingActionId: response.data.result.sharingactionid,
          zipFileName: action.validFilename
        }
        yield put(actions.getDownloadSharingStatus(reqPayload, action.validFilename));
      } else {
        yield put(fileUploadActions.showDownloadLoader(false));
      }
    } else {
      yield put(fileUploadActions.showDownloadLoader(false));
    }
  } catch (error) {    
    yield put(fileUploadActions.showDownloadLoader(false));
  }
}
export function* getDownloadSharingDetailsStatusSaga(action) {
  try {
    yield put(fileUploadActions.showDownloadLoader(true));
    let response = yield DocumentService.getDownloadSharingDetailsStatus(action.payload);
    if(!response.data.isError) {
      switch (response.data.result.fileStatus) {
        case "CreatingZipFile":
          yield put(actions.getDownloadSharingStatus(action.payload, action.validFilename));
          break;
        case "ZipFileUploaded":
              var link = document.createElement('a');
              link.href = response.data.result.fileSASToken;
              link.click();
            yield put(fileUploadActions.showDownloadLoader(false));      
            break;  
        default:
          yield put(fileUploadActions.showDownloadLoader(false));      
          break;
      }
    }
  } catch (error) {
    yield put(fileUploadActions.showDownloadLoader(false));
  }
}

export function* getHistoryDetailsSaga(data) {
  const {id, lastDateTime, versionId} = data?.payload
  try {
    const response = yield DocumentService.doGetDocHistory(id, lastDateTime, versionId);
    const { isError } = response.data;
    if (!isError) {
      yield put(actions.getHistoryDetailsSuccess(response?.data?.result));
    } else {
      yield put(actions.getHistoryDetailsFailure());
      yield put(dialogActions.updateErrorAlertDialog(i18n.t("TMF.TOAST_SOMETHINGWENTWRONG")));
    }
  } catch (error) {
    yield put(actions.getHistoryDetailsFailure());
    yield put(dialogActions.updateErrorAlertDialog(i18n.t("TMF.TOAST_SOMETHINGWENTWRONG")));
  }
}

export function* watchDocumentAsync() {
  yield takeEvery(actionTypes.FETCH_DOCUMENT_MASTER, fetchDocumentMaster);
  yield takeEvery(actionTypes.DOCUMENT_STATUS_CHANGE, documentStatusChange);
  yield takeEvery(actionTypes.FETCH_DOCUMENT_DELETE, deleteDocumentSaga);
  yield takeEvery(actionTypes.FETCH_DOCUMENT_LOCK, fetchDocumentLockSaga);
  yield takeEvery(actionTypes.GET_DOCUMENT_VERSION_LIST, doGetDocumentVersionListSaga);
  yield takeEvery(actionTypes.GET_SELECTED_DOCUMENT_DETAIL, getSelectedDocumentDetailSaga);
  yield takeEvery(actionTypes.ADD_DOC_NOTE, addDocNoteSaga);
  yield takeEvery(actionTypes.GET_DOC_NOTE, getDocNoteSaga);
  yield takeEvery(actionTypes.RESOLVE_DOC_NOTE, resolveDocNoteSaga);
  yield takeEvery(actionTypes.GET_SHARED_FILE_COUNT, getSharedFileCount);
  yield takeEvery(actionTypes.UPDATE_USER_SHARED_LIST, updateUserSharedListSaga);
  yield takeEvery(actionTypes.GET_DOC_PROP_HISTROY, getDocPropHistorySaga);
  yield takeEvery(actionTypes.GET_SHARING_METRICS_BY_VERSION, getDocSharingMetricsByVersionIdSaga);
  yield takeEvery(actionTypes.GET_DOC_SHARE_USER_LIST, getDocSharingUserSaga);
  yield takeEvery(actionTypes.GET_EXISTING_SHARED_USER_LIST, getExistingSharedUserListSaga);
  yield takeEvery(actionTypes.GET_BINDERS_DETAIL, getBindersDetailsSaga);
  yield takeEvery(actionTypes.CREATE_NEW_BINDER_FOR_SHARING, createNewBinderForSharingSaga);
  yield takeEvery(actionTypes.GET_DOCUMENT_CONTENT_DETAILS, getDocumentContentDetailsSaga);
  yield takeEvery(actionTypes.RECALL_BINDER, recallBinderSaga);
  yield takeEvery(actionTypes.GET_BINDERS_HISTORY, getBindersHistorySaga);
  yield takeEvery(actionTypes.GET_ADMIN_SHARING_SETTING, getAdminsharingsSettingSaga);
  yield takeEvery(actionTypes.DOWNLOAD_SIGNATURE_DETAILS, downloadSignatureDetailsSaga);
  yield takeLatest(actionTypes.GET_HISTORY_DETAILS, getHistoryDetailsSaga);
  yield takeEvery(actionTypes.DOWNLOAD_SHARING_DETAILS, downloadSharingDetailsSaga);
  yield takeEvery(actionTypes.GET_DOWNLOAD_SHARING_STATUS, getDownloadSharingDetailsStatusSaga);
  yield takeEvery(actionTypes.DOWNLOAD_SIGNATURE_ZIP_DETAILS, downloadSignatureZipDetailsSaga);
}
